import type { Nullable } from 'core/core.models';
import type { Crop, UUID } from 'core/utils/basic.models';
import type { LicensingStatusResponse } from 'entities/company/company.models';
import type { CurrentSeasonArea } from 'entities/property/property.models';
import type { Region, RegionWithFeatures } from 'entities/region/region.models';
import type { Feature, FeatureCollection } from 'geojson';
import type { Dictionary } from 'lodash';
import { isEmpty } from 'lodash';
import type { LastSprays } from 'querys/disease-risk/disease-risk.query.models';
import type { DiseaseRiskFieldData } from '../disease-risk.models';
import {
  defineIfRegionHasEndedSeason,
  defineIfRegionIsHarvested,
  filterRegionTypeForDiseaseRisk,
  getRiskColor
} from '../utils/disease-risk.utils';

const isAValidDiseaseRiskFeatureCollection = (features: Feature[]): boolean => {
  let hasSeed = false;
  let hasRiskColor = false;

  for (const innerFeature of features) {
    if (typeof innerFeature.properties !== 'object') break;

    if (!hasSeed && Object.prototype.hasOwnProperty.call(innerFeature.properties, 'seed')) {
      hasSeed = true;
    }

    if (!hasRiskColor && Object.prototype.hasOwnProperty.call(innerFeature.properties, 'riskColor')) {
      hasRiskColor = true;
    }

    if (hasSeed && hasRiskColor) {
      break;
    }
  }

  return hasSeed && hasRiskColor;
};

const isAValidGeometry = (geometry: Feature['geometry']): boolean => {
  if (geometry.type === 'GeometryCollection') {
    return !!geometry.geometries.length && !geometry.geometries.some(geometry => !isAValidGeometry(geometry));
  }

  return !!geometry.coordinates.length;
};

const isAValidRegionGeometry = (
  geometry: Region['geometry'],
  customMatcherForFeatureCollection?: (features: Feature[]) => boolean,
  useOnlyCustomMatcher = false
): boolean => {
  switch (geometry?.type) {
    case 'Feature': {
      return isAValidGeometry(geometry.geometry);
    }
    case 'FeatureCollection': {
      return (
        (customMatcherForFeatureCollection?.(geometry.features) ?? true) &&
        (!useOnlyCustomMatcher
          ? !!geometry.features.length &&
            !geometry.features.some(feature => {
              return !isAValidGeometry(feature.geometry!);
            })
          : true)
      );
    }
    default:
      return false;
  }
};

const recalculateFeatureCollectionWithRisks = ({
  features,
  regions,
  selectedCrops,
  licensingStatus,
  timezone,
  fieldsRisk,
  wheatRegionIds,
  plantingDay,
  lastFungicideSprays,
  currentAreaSeasons,
  selectedSeasons
}: {
  features: Feature[];
  regions: Dictionary<Region>;
  selectedCrops: Crop[];
  licensingStatus: Nullable<LicensingStatusResponse>;
  timezone: string;
  fieldsRisk: Dictionary<DiseaseRiskFieldData>;
  wheatRegionIds: UUID[];
  plantingDay?: string;
  lastFungicideSprays: LastSprays;
  currentAreaSeasons: CurrentSeasonArea[];
  selectedSeasons: string[];
}): Feature[] =>
  features.reduce<FeatureCollection['features']>((accumulator, feature) => {
    const featureId = feature.properties?.id as string;
    if (!feature?.properties || !filterRegionTypeForDiseaseRisk(regions[featureId], selectedCrops, licensingStatus)) return accumulator;

    const endedSeason = defineIfRegionHasEndedSeason({
      timeZone: timezone,
      field: regions[featureId],
      currentAreaSeasons,
      selectedSeasons
    });
    const isHarvested = defineIfRegionIsHarvested(regions[featureId], timezone);

    let riskColor = 'transparent';
    if (!endedSeason && !isHarvested && wheatRegionIds.includes(featureId)) {
      riskColor = getRiskColor(fieldsRisk[featureId]?.riskMap);
    }

    let noData = !isHarvested && isEmpty(fieldsRisk[featureId]?.status?.status);
    if (!noData) {
      noData = !fieldsRisk[featureId];
    }

    accumulator.push({
      ...feature,
      properties: {
        ...feature.properties,
        riskColor,
        noData,
        status: fieldsRisk[featureId]?.status?.status,
        sowing_date: plantingDay,
        lastFungicideSpray: lastFungicideSprays?.[featureId]
      }
    });

    return accumulator;
  }, []);

interface GetShouldShowMapGeometryErrorMessageProps {
  isCurrentInfoLoaded: boolean;
  isMapLoading: boolean;
  hasRegionFeatureLength: boolean;
  validGeometry: boolean;
}
const getShouldShowMapGeometryErrorMessage = ({
  isCurrentInfoLoaded,
  isMapLoading,
  hasRegionFeatureLength,
  validGeometry
}: GetShouldShowMapGeometryErrorMessageProps): boolean => isCurrentInfoLoaded && isMapLoading && hasRegionFeatureLength && !validGeometry;

export {
  getShouldShowMapGeometryErrorMessage,
  isAValidDiseaseRiskFeatureCollection,
  isAValidGeometry,
  isAValidRegionGeometry,
  recalculateFeatureCollectionWithRisks
};

export const getHasRegionFeatureLength = (region: RegionWithFeatures | null): boolean => !!region?.geometry?.features?.length;

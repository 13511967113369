import { getSelectedCompany } from 'entities/company/company.reducer';
import { getSelectedProperty } from 'entities/property/property.reducer';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import type { AnalyticsInfo } from './user-events.types';

type AdditionalAnalyticsInfo = Omit<AnalyticsInfo, 'companyId' | 'propertyId' | 'companyName' | 'propertyName'>;

export interface SegmentTrackingHookReturn {
  track: (message: string, additionalPayload?: AdditionalAnalyticsInfo) => void;
  identify: (traits: Record<string, unknown>) => void;
}

const handleError = (condition: boolean, message: string) => {
  if (condition) {
    console.error(message);
  }
};

const useSegmentTracking = (includeCompany = true, includeProperty = true): SegmentTrackingHookReturn => {
  const company = useSelector(getSelectedCompany);
  const property = useSelector(getSelectedProperty);

  const companyPayload = useMemo(
    () => (includeCompany && company ? { companyId: company.id, companyName: company.name } : {}),
    [company, includeCompany]
  );

  const propertyPayload = useMemo(
    () => (includeProperty && property ? { propertyId: property.id, propertyName: property.name } : {}),
    [property, includeProperty]
  );

  const handleTrack = useCallback(
    (action: string, additionalInfo: AdditionalAnalyticsInfo = {}) => {
      handleError(!company && includeCompany, `Company is not present when tracking action ${action}.`);
      handleError(!property && includeProperty, `Property is not present when tracking action ${action}.`);

      const payload: AnalyticsInfo = {
        ...companyPayload,
        ...propertyPayload,
        ...additionalInfo
      };

      window.analytics.track(action, payload);
    },
    [company, property, includeCompany, includeProperty, propertyPayload, companyPayload]
  );

  return useMemo(
    () => ({
      track: handleTrack,
      identify: traits => window.analytics.identify(traits)
    }),
    [handleTrack]
  );
};

export default useSegmentTracking;

import type { Dictionary } from 'config/types';
import type moment from 'moment';
import type { ReactElement } from 'react';

export type UUID = string;
export type Timestamp = number;

export enum FeatureFlags {
  NO_TIMELINE = 'NO_TIMELINE',
  NO_HEATMAP = 'NO_HEATMAP'
}

export enum Direction {
  ASC = 'ASC',
  DESC = 'DESC',
  ASC_ALPHABETIC = 'ASC_ALPHABETIC',
  DESC_ALPHABETIC = 'DESC_ALPHABETIC',
  ASC_LAST_VISIT = 'ASC_LAST_VISIT',
  DESC_LAST_VISIT = 'DESC_LAST_VISIT'
}

export interface LocalizedString {
  localized_strings: Record<string, string>;
}

export interface Action<T = undefined> {
  type: string;
  payload?: T;
}

export interface IndexedName {
  id: UUID;
  name: string;
  categoryName?: string;
}

export interface IndexedNameDescription {
  id: UUID;
  name: I18nMapDTO | string;
  short_description?: I18nMapDTO | string;
}

export interface SimplePhenomena {
  id: UUID;
  name: I18nMapDTO;
  short_description?: I18nMapDTO;
  category: string;
  methodologyId: UUID;
  companyId?: string | null;
}

export interface Period {
  time: number;
  time_unit: TimeUnitType;
}

export interface I18nMapDTO {
  localized_strings: Dictionary<string>;
}

/**
 * Interface to use when requesting pageable data from server
 */
export interface PageableQuery {
  query?: QueryParams;
  pageable: Pageable;
}

interface Sort {
  direction: Direction;
  orders: Order[];
}
interface Order {
  direction: Direction;
  property: string;
}
interface QueryParams {
  params: Map<string, string>;
}
interface Pageable {
  page: number;
  size: number;
  sort?: Sort;
}
export interface Page<T> {
  number: number;
  size: number;
  pages: number;
  total: number;
  // @todo remove mutable when we have backend
  mutableContent: T[];
}

export interface PageNoContent {
  last: boolean;
  total_elements: number;
  total_pages: number;
  size: number;
  number: number;
  number_of_elements: number;
  first: boolean;
  empty: boolean;
}

export interface V4Page<T> {
  content: T[];
  last: boolean;
  total_elements: number;
  total_pages: number;
  totalPages?: number;
  size: number;
  number: number;
  number_of_elements: number;
  first: boolean;
  empty: boolean;
  companyId?: UUID;
}

export const initialPage = {
  number: 0,
  size: 0,
  pages: 0,
  total: 0,
  total_pages: 0,
  mutableContent: []
};

export const initialV4Page = {
  content: [],
  empty: false,
  first: false,
  last: false,
  number: 0,
  number_of_elements: 0,
  size: 0,
  total_elements: 0,
  total_pages: 0
};

type IndicatorTrend = 'INCREASE' | 'DECREASE' | 'NONE';
type ThresholdStatus = 'ACCEPTANCE' | 'DAMAGE' | 'CONTROL' | 'NONE';

export interface Indicator {
  id: string;
  name: string;
  value: number;
  double_value: number;
  decimal_places?: number;
  feature_flags?: FeatureFlags[];
  trend?: IndicatorTrend;
  status: ThresholdStatus;
  color?: string;
  unit: string;
}

type LatLng = [number, number];
type Datetime = number;

export type DateISOString = string;
export type DateOnlyString = string;

export type Moment = moment.Moment;
interface Point {
  coordinates: LatLng;
  date?: Datetime;
}

export interface Scouter {
  id: UUID;
  name: string;
  hiking_distance?: number;
  hiking_path?: GeoJSON.LineString;
  points?: Point[];
  tracking?: Tracking[];
}

interface Tracking {
  distance: number;
  unit: string;
  path: LatLng[];
}

export interface Phenomenon {
  id: string;
  name: string;
  short_description: string;
  thumbnail?: string;
  methodologyIds?: UUID[];
  indicators: Indicator[];
}

export interface Crop {
  id: UUID;
  name: string;
  wk_slug: string;
}

export interface MultipleIndicator extends Indicator {
  phenomenons: Phenomenon[];
}

export enum UnitType {
  KILOGRAMS = 'KILOGRAMS',
  LITERS = 'LITERS',
  UNITS = 'UNITS',
  BOXS = 'BOXS',
  SACK = 'SACK',
  GALLONS = 'GALLONS',
  GRAMS = 'GRAMS',
  TONNES = 'TONNES',
  MILLILITER = 'MILLILITER',
  METERS = 'METERS',
  OUNCES = 'OUNCES',
  POUNDS = 'POUNDS',
  PINTS = 'PINTS',
  DOSE = 'DOSE'
}

export enum TimeUnitType {
  MILLISECOND = 'MILLISECOND',
  NANOSECOND = 'NANOSECOND',
  MICROSECOND = 'MICROSECOND',
  SECOND = 'SECOND',
  MINUTE = 'MINUTE',
  HOUR = 'HOUR',
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  YEAR = 'YEAR'
}

export enum ProductType {
  // Others
  VARIETY = 'VARIETY',
  FUEL = 'FUEL',
  PART = 'PART',
  GENERIC_PRODUCT = 'GENERIC_PRODUCT',
  LUBRICANT = 'LUBRICANT',
  INOCULANT = 'INOCULANT',

  // Defensive
  ADJUVANT = 'ADJUVANT',
  HERBICIDE = 'HERBICIDE',
  FUNGICIDE = 'FUNGICIDE',
  FERTILIZER = 'FERTILIZER',
  PESTICIDE = 'PESTICIDE',
  INSECTICIDE = 'INSECTICIDE'
}

export enum LissThresholds {
  MIN_CONTROL = 60,
  MIN_DAMAGE = 90
}

export interface Notification {
  success: {
    message: string;
    description: string;
    icon?: ReactElement;
  };
  error: {
    message: string;
    description: string;
    icon: ReactElement;
  };
  pestCodeError: {
    message: string;
    description: string;
    icon: ReactElement;
  };
}

interface BasicUser {
  id: UUID;
  name: string;
}

export interface CommandRequest<T> {
  account: BasicUser;
  payload: T;
}

export interface DefaultActionReturn<T> {
  type: string;
  payload: T;
}

import { CoreActionsTypes } from 'core/core.actions';
import { selectSelectedProperty } from 'core/core.reducer';
import { getPropertyEntitiesInEntities, getSelectedPropertyId } from 'core/core.selectors';
import type { UUID } from 'core/utils/basic.models';
import { createDictionaryFromList } from 'core/utils/functions';
import {
  ClearIndicatorPressure,
  LoadSeverityByIndicatorSuccess,
  PropertyActionsTypes,
  SetUseNewMapColors
} from 'entities/property/property.actions';
import type { Property } from 'entities/property/property.models';
import { getSelectedProperty, selectPropertyEntities } from 'entities/property/property.reducer';
import type { Feature } from 'geojson';
import _, { isEmpty } from 'lodash';
import type { Dictionary, EntityAdapter, Update } from 'redux-ngrx-entity';
import { createEntityAdapter } from 'redux-ngrx-entity';
import type { AppState } from 'redux/app-state';
import { createSelector } from 'reselect';
import type { TimelineWindowEvents } from '../../pages/timeline/timeline.models';
import * as RegionActions from './region.actions';
import type { CurrentInfo, Region, RegionState } from './region.models';
import { AreaVariableMassiveStages, RegionType } from './region.models';
import { getUpdatedChildRegionsWithIndicatorPressure, getUpdatedParentRegionsWithIndicatorPressure } from './region.utils';

const { RegionActionsTypes, ...RegionActionsFunctions } = RegionActions;
const PropertyActionsFunctions = {
  LoadSeverityByIndicatorSuccess,
  ClearIndicatorPressure,
  SetUseNewMapColors
};

export type ActionsResponses =
  | ReturnType<(typeof RegionActionsFunctions)[keyof typeof RegionActionsFunctions]>
  | ReturnType<(typeof PropertyActionsFunctions)[keyof typeof PropertyActionsFunctions]>;

const mapRegionToRegionUpdate = (region: Region): Update<Region> => ({ id: region.id, changes: region });

function selectRegionUuid(region: Region): string {
  return region.id;
}

function sortByName(a: Region, b: Region): number {
  return a.name.localeCompare(b.name);
}

const entity: EntityAdapter<Region> = createEntityAdapter<Region>({
  selectId: selectRegionUuid,
  sortComparer: sortByName
});

const setSeasonMethodologies = (
  methodologies: Dictionary<{
    seasonId?: UUID;
    methodologyId: UUID;
  }>,
  regions: Dictionary<Region>
) => {
  return Object.keys(methodologies).map(regionId => {
    if (!regions[regionId]) {
      return {
        id: regionId,
        changes: {}
      };
    }
    const parsedSeasons = regions[regionId].seasons.map(season => {
      if (season.id !== methodologies[regionId].seasonId) return season;

      return {
        ...season,
        methodologyIds: [methodologies[regionId].methodologyId]
      };
    });

    return {
      id: regionId,
      changes: {
        seasons: parsedSeasons
      }
    };
  });
};

export const initialState: RegionState = entity.getInitialState({
  isTableInfoLoading: true,
  areaVariablesPerSeason: {},
  isLoading: false,
  isLoadingGeometry: false,
  isCurrentInfoLoaded: false,
  isCurrentInfoLoading: false,
  isRegionsLoaded: false,
  error: null,
  choroplethLimits: {},
  mapLayersColorDictionary: {},
  latestWindowEvents: null,
  isLoadingEvents: false,
  isMethodologyLoaded: false,
  customVariables: [],
  areaVariables: [],
  isLoadingCustomVariables: false,
  isLoadingAreaVariables: false,
  isLoadingRegionData: false,
  regionData: [],
  isLoadingCoverageGroup: false,
  fieldsRepresentativeness: {},
  /** @deprecated this will be removed soon */
  seasonAreas: [],
  /** @deprecated use seasonFieldsCropsVarieties instead */
  seasonAreasCropsVarieties: {},
  nextSprayCurrentInfoFields: [],
  shouldStoreScoutingScores: false,
  shouldStoreNematodeDamage: false,
  shouldUpdateColors: false,
  useNewMapColors: false,
  currentInfoRegions: {},
  timelineMapHighlightEnabled: false,
  seasonFieldsCropsVarieties: null,
  recursiveRegionsAndSeasonFields: null
});

export const hasOtherEvents = (events: TimelineWindowEvents) => {
  return events.annotation_window ?? events.monitoring_window ?? events.phenology_window ?? events.spray_window;
};

export default (state = initialState, action: ActionsResponses): RegionState => {
  switch (action.type) {
    case RegionActionsTypes.LOAD_REGION_METHODOLOGIES_SUCCESS:
      return entity.updateMany(setSeasonMethodologies(action.payload, state.entities), {
        ...state,
        isMethodologyLoaded: true
      });

    case RegionActionsTypes.LOAD_REGION_SEASON_FIELD:
    case RegionActionsTypes.LOAD_REGION_SEASON_AREA: {
      return {
        ...state,
        isTableInfoLoading: true,
        areaVariableLoadingStage: AreaVariableMassiveStages.LOADING_SEASON_AREAS
      };
    }
    case CoreActionsTypes.SELECT_PROPERTY: {
      return entity.removeAll({
        ...state,
        isRegionsLoaded: false
      });
    }
    case RegionActionsTypes.LOAD_REGION_SEASON_FIELD_FAILED:
    case RegionActionsTypes.LOAD_REGION_SEASON_FIELD_SUCCESS:
      return {
        ...state,
        isTableInfoLoading: false,
        recursiveRegionsAndSeasonFields: action.payload.areaInfo,
        seasonFieldsCropsVarieties: action.payload.seasonFieldsCropsVarieties,
        areaVariableLoadingStage: undefined
      };

    case RegionActionsTypes.LOAD_REGION_SEASON_AREA_SUCCESS:
      return {
        ...state,
        isTableInfoLoading: false,
        recursiveRegionsAndSeasonAreas: action.payload.areaInfo,
        seasonAreas: action.payload.seasonAreas,
        seasonAreasCropsVarieties: action.payload.seasonAreasCropsVarieties,
        areaVariableLoadingStage: undefined
      };

    case RegionActionsTypes.GET_AREA_VARIABLES: {
      return {
        ...state,
        isTableInfoLoading: true,
        areaVariableLoadingStage: AreaVariableMassiveStages.LOADING_AREA_VARIABLES
      };
    }
    case RegionActionsTypes.EDIT_AREA_VARIABLES_MASSIVELY: {
      return {
        ...state,
        isTableInfoLoading: true,
        areaVariableLoadingStage: AreaVariableMassiveStages.EDITING_AREA_VARIABLES
      };
    }
    case RegionActionsTypes.DELETE_AREA_VARIABLE: {
      return {
        ...state,
        isTableInfoLoading: true,
        areaVariableLoadingStage: AreaVariableMassiveStages.DELETING_AREA_VARIABLES,
        isLoadingAreaVariables: true,
        error: null
      };
    }
    case RegionActionsTypes.CREATE_AREA_VARIABLES_MASSIVELY: {
      return {
        ...state,
        isTableInfoLoading: true,
        areaVariableLoadingStage: AreaVariableMassiveStages.CREATING_AREA_VARIABLES
      };
    }

    case RegionActionsTypes.EDIT_AREA_VARIABLES_MASSIVELY_FAILURE:
    case RegionActionsTypes.CREATE_AREA_VARIABLES_MASSIVELY_FAILURE:
    case RegionActionsTypes.EDIT_SEASON_AREA_DATES_MASSIVELY_FAILURE:
    case RegionActionsTypes.EDIT_SEASON_AREA_VARIETIES_MASSIVELY_FAILURE:
      return {
        ...state,
        isTableInfoLoading: false,
        areaVariableLoadingStage: undefined
      };

    case RegionActionsTypes.EDIT_SEASON_AREA_DATES_MASSIVELY: {
      return {
        ...state,
        isTableInfoLoading: true,
        areaVariableLoadingStage: AreaVariableMassiveStages.SAVING_DATES
      };
    }
    case RegionActionsTypes.EDIT_SEASON_AREA_VARIETIES_MASSIVELY: {
      return {
        ...state,
        isTableInfoLoading: true,
        areaVariableLoadingStage: AreaVariableMassiveStages.SAVING_VARIETIES
      };
    }
    case RegionActionsTypes.GET_AREA_VARIABLES_SUCCESS: {
      return {
        ...state,
        areaVariablesPerSeason: action.payload,
        isTableInfoLoading: false,
        areaVariableLoadingStage: undefined
      };
    }
    case RegionActionsTypes.UPDATE_REGION:
      return entity.updateOne(
        {
          id: action.payload.id,
          changes: {
            current_info: action.payload.current_info
          }
        },
        {
          ...state,
          isLoading: false,
          error: null
        }
      );
    case RegionActionsTypes.LOAD_REGION:
      return {
        ...state,
        isRegionsLoaded: false,
        isLoading: true,
        isCurrentInfoLoaded: false
      };
    case RegionActionsTypes.RELOAD_REGION:
      return {
        ...initialState,
        isRegionsLoaded: false,
        isLoading: true,
        isCurrentInfoLoaded: false,
        useNewMapColors: state.useNewMapColors,
        regionData: state.regionData
      };
    case RegionActionsTypes.LOAD_REGION_SUCCESS:
      return entity.addMany(action.payload, {
        ...state,
        isRegionsLoaded: true,
        isLoading: false,
        error: null
      });
    case RegionActionsTypes.RELOAD_REGION_DETAILED_INFO:
      return {
        ...state,
        isCurrentInfoLoaded: false,
        error: null
      };
    case RegionActionsTypes.LOAD_REGION_DETAILED_INFO:
    case RegionActionsTypes.LOAD_CURRENT_INFOS_BY_FIELD_IDS:
      return {
        ...state,
        isCurrentInfoLoaded: false,
        isCurrentInfoLoading: true,
        error: null
      };
    case RegionActionsTypes.LOAD_CURRENT_INFOS_BY_FIELDS_SUCCESS:
      return entity.upsertMany([...action.payload], {
        ...state,
        isCurrentInfoLoaded: true,
        isCurrentInfoLoading: false,
        shouldStoreScoutingScores: true,
        shouldStoreNematodeDamage: true,
        shouldUpdateColors: true,
        error: null
      });
    case RegionActionsTypes.LOAD_REGION_DETAILED_INFO_SUCCESS: {
      const stateRegionIds: (UUID | number)[] = state.ids;
      const currentRegionsFromPayload = action.payload.regions.filter(({ id }) => stateRegionIds.includes(id));

      if (isEmpty(currentRegionsFromPayload)) return state;

      return entity.upsertMany(currentRegionsFromPayload, {
        ...state,
        isCurrentInfoLoaded: true,
        choroplethLimits: action.payload.choroplethLimits ?? state.choroplethLimits,
        mapLayersColorDictionary: action.payload.mapLayersColorDictionary,
        error: null,
        shouldStoreScoutingScores: true,
        shouldStoreNematodeDamage: true,
        shouldUpdateColors: true,
        currentInfoRegions: action.payload.currentInfoRegions
      });
    }
    case RegionActionsTypes.LOAD_REGION_DETAILED_INFO_FAILURE:
    case RegionActionsTypes.LOAD_CURRENT_INFOS_BY_FIELDS_FAILURE:
      return {
        ...state,
        isCurrentInfoLoaded: true,
        isCurrentInfoLoading: false,
        error: action.payload
      };
    case RegionActionsTypes.LOAD_REGION_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case RegionActionsTypes.REGION_ALREADY_LOADED:
      return {
        ...state,
        isLoading: false,
        isCurrentInfoLoaded: true
      };
    case RegionActionsTypes.LOAD_LATEST_WINDOW_EVENTS:
      return {
        ...state,
        isLoadingEvents: true,
        latestWindowEvents: null
      };
    case RegionActionsTypes.LOAD_LATEST_WINDOW_EVENTS_SUCCESS:
      return {
        ...state,
        isLoadingEvents: false,
        latestWindowEvents: action.payload
      };
    case RegionActionsTypes.LOAD_LATEST_WINDOW_EVENTS_FAILURE:
      return {
        ...state,
        isLoadingEvents: false,
        latestWindowEvents: null,
        error: action.payload
      };
    case RegionActionsTypes.UPDATE_REGION_SCOUTING_SCORE_SUCCESS:
      return entity.upsertMany(action.payload.regions, {
        ...state,
        shouldStoreScoutingScores: false
      });
    case RegionActionsTypes.UPDATE_REGION_NEMATODE_DAMAGE_SUCCESS:
      return entity.upsertMany(action.payload.regions, {
        ...state,
        shouldStoreNematodeDamage: false
      });
    case RegionActionsTypes.UPDATE_REGION_SEED_AND_PHENOLOGY_COLORS_SUCCESS:
      return entity.upsertMany(action.payload.regions, {
        ...state,
        mapLayersColorDictionary: action.payload.mapLayersColorDictionary,
        shouldUpdateColors: false
      });
    case RegionActionsTypes.TIMELINE_MAP_HIGHLIGHT_FILTER:
      return {
        ...state,
        timelineMapHighlightEnabled: !!action.payload.value
      };
    case RegionActionsTypes.TIMELINE_MAP_HIGHLIGHT_FILTER_SUCCESS:
      return entity.upsertMany(action.payload.regions, state);

    case RegionActionsTypes.REMOVE_TASKS_EVENT: {
      if (!state.latestWindowEvents?.harvest_window) return state;
      const updatedTasks = state.latestWindowEvents.harvest_window.harvests.filter(task => {
        return task.id !== action.payload.taskId;
      });

      if (hasOtherEvents(state.latestWindowEvents) ?? !!updatedTasks.length) {
        return {
          ...state,
          latestWindowEvents: {
            ...state.latestWindowEvents,
            harvest_window: updatedTasks.length
              ? {
                  ...state.latestWindowEvents.harvest_window,
                  harvests: updatedTasks
                }
              : undefined
          }
        };
      }
      return {
        ...state,
        latestWindowEvents: null
      };
    }

    case RegionActionsTypes.REMOVE_SPRAY_EVENT: {
      if (!state.latestWindowEvents?.spray_window) return state;
      const updatedSprays = state.latestWindowEvents.spray_window.sprays.filter(spray => {
        if (action.payload.taskId) {
          return spray.task_id !== action.payload.taskId;
        }

        return spray.id !== action.payload.sprayId;
      });

      if (hasOtherEvents(state.latestWindowEvents) ?? !!updatedSprays.length) {
        return {
          ...state,
          latestWindowEvents: {
            ...state.latestWindowEvents,
            spray_window: updatedSprays.length
              ? {
                  ...state.latestWindowEvents.spray_window,
                  sprays: updatedSprays
                }
              : undefined
          }
        };
      }
      return {
        ...state,
        latestWindowEvents: null
      };
    }
    case RegionActionsTypes.CANCEL_LOAD_WINDOW_EVENTS:
      return {
        ...state,
        isLoadingEvents: false,
        latestWindowEvents: null
      };
    case RegionActionsTypes.GET_AREA_VARIABLE:
      return {
        ...state,
        isLoadingAreaVariables: true,
        error: null
      };
    case RegionActionsTypes.GET_AREA_VARIABLE_SUCCESS:
      return {
        ...state,
        isLoadingAreaVariables: false,
        areaVariables: action.payload,
        error: null
      };
    case RegionActionsTypes.GET_AREA_VARIABLE_FAILURE:
      return {
        ...state,
        isLoadingAreaVariables: false,
        error: action.payload
      };
    case RegionActionsTypes.DELETE_AREA_VARIABLE_FAILURE:
      return {
        ...state,
        isLoadingAreaVariables: false,
        error: action.payload,
        isTableInfoLoading: false,
        areaVariableLoadingStage: undefined
      };
    case RegionActionsTypes.GET_CUSTOM_VARIABLE_SUCCESS:
      return {
        ...state,
        isLoadingCustomVariables: false,
        customVariables: action.payload,
        error: null
      };
    case RegionActionsTypes.GET_CUSTOM_VARIABLE_FAILURE:
      return {
        ...state,
        isLoadingCustomVariables: false,
        error: action.payload
      };
    case RegionActionsTypes.DELETE_CUSTOM_VARIABLE_SUCCESS:
      return {
        ...state,
        isLoadingCustomVariables: false,
        customVariables: state.customVariables.filter(item => item.id !== action.payload),
        error: null
      };
    case RegionActionsTypes.DELETE_CUSTOM_VARIABLE_FAILURE:
      return {
        ...state,
        isLoadingCustomVariables: false,
        error: action.payload
      };
    case RegionActionsTypes.GET_REGION_DATA:
      return {
        ...state,
        isLoadingRegionData: true,
        error: null
      };
    case RegionActionsTypes.GET_REGION_DATA_SUCCESS:
      return {
        ...state,
        isLoadingRegionData: false,
        regionData: [...state.regionData, ...action.payload]
      };
    case RegionActionsTypes.CLEAR_REGION_DATA:
      return {
        ...state,
        isLoadingRegionData: false,
        regionData: initialState.regionData
      };

    case RegionActionsTypes.GET_AREA_DAY_REPRESENTATIVENESS:
      return {
        ...state,
        isLoadingCoverageGroup: true
      };

    case RegionActionsTypes.GET_AREA_DAY_REPRESENTATIVENESS_SUCCESS:
      return {
        ...state,
        isLoadingCoverageGroup: false,
        fieldsRepresentativeness: {
          ...state.fieldsRepresentativeness,
          [action.payload.id]: [...(state.fieldsRepresentativeness[action.payload.id] || []), action.payload]
        }
      };

    case RegionActionsTypes.DELETE_PHENOLOGY_SUCCESS: {
      const { sample } = action.payload;
      const windowEvents = state.latestWindowEvents;

      if (!windowEvents?.phenology_window) {
        return state;
      }

      const stageSamples = windowEvents.phenology_window.samples.filter(
        ({ id, stage }) => id !== sample.id && stage.id === sample.stage.id
      );
      const filteredSamples = windowEvents.phenology_window.samples.filter(phenologySample => phenologySample.id !== sample.id);
      const hasOtherEvents = windowEvents?.monitoring_window ?? windowEvents?.spray_window ?? windowEvents?.annotation_window;

      if (!filteredSamples.length && !hasOtherEvents) {
        return {
          ...state,
          latestWindowEvents: null
        };
      }

      if (!stageSamples.length) {
        return {
          ...state,
          latestWindowEvents: {
            ...windowEvents,
            phenology_window: {
              ...windowEvents.phenology_window,
              samples: filteredSamples,
              stages: windowEvents.phenology_window.stages.filter(({ stage }) => stage.id !== sample.stage.id)
            }
          }
        };
      }

      return {
        ...state,
        latestWindowEvents: {
          ...windowEvents,
          phenology_window: {
            ...windowEvents.phenology_window,
            samples: filteredSamples,
            stages: windowEvents.phenology_window.stages.map(phenologyStage => ({
              ...phenologyStage,
              count: phenologyStage.stage.id === sample.stage.id ? phenologyStage.count - 1 : phenologyStage.count
            }))
          }
        }
      };
    }

    case PropertyActionsTypes.LOAD_SEVERITY_BY_INDICATOR_SUCCESS: {
      const hasPressureIndicator: boolean | undefined = verifyPressureIndicator(action);
      const indicatorPressureResults = action.payload.data;
      const indicatorPressureResultMap = createDictionaryFromList(indicatorPressureResults, 'area_id');
      const updatedChildRegions = getUpdatedChildRegionsWithIndicatorPressure(
        state.entities,
        indicatorPressureResultMap,
        state.useNewMapColors
      );
      const updatedParentRegions = getUpdatedParentRegionsWithIndicatorPressure(state.entities, updatedChildRegions);
      return entity.updateMany([...updatedChildRegions, ...updatedParentRegions].map(mapRegionToRegionUpdate), {
        ...state,
        severityIndicatorSelected: action.payload.indicator,
        hasPressureIndicator
      });
    }
    case PropertyActionsTypes.CLEAR_INDICATOR_SEVERITY: {
      const updatedChildRegions = getUpdatedChildRegionsWithIndicatorPressure(state.entities, {});
      const updatedParentRegions = getUpdatedParentRegionsWithIndicatorPressure(state.entities, updatedChildRegions);
      return entity.updateMany([...updatedChildRegions, ...updatedParentRegions].map(mapRegionToRegionUpdate), {
        ...state,
        severityIndicatorSelected: undefined,
        hasPressureIndicator: true
      });
    }

    case PropertyActionsTypes.SET_USE_NEW_MAP_COLORS_FLAG: {
      return {
        ...state,
        useNewMapColors: action.payload
      };
    }
    default:
      return state;
  }
};

const { selectIds, selectEntities, selectAll, selectTotal } = entity.getSelectors();
export const selectRegionIds = selectIds;
export const selectRegionEntities = selectEntities;
export const selectAllRegions = selectAll;
export const selectRegionTotal = selectTotal;

export const selectRegion = (state: AppState, regionId: UUID) => state.entities.region.entities[regionId];

export const selectChildren = (parentId: UUID) =>
  createSelector(
    (state: AppState) => selectAll(state.entities.region),
    regions => regions.filter(region => region.parent_id === parentId)
  );

const getRootRegion = (regions: Region[], properties: Dictionary<Property>, selectedProperty: string, showFieldsCropEnded = true) => {
  let rootRegion = regions.find(region => region.id === properties[selectedProperty].root_region_id) ?? null;

  if (!rootRegion) {
    const regionsWithChildren = regions.filter(region => !!region.children?.length);
    if (regionsWithChildren.length === 1) {
      [rootRegion] = regionsWithChildren;
    }
  }

  if (rootRegion && !showFieldsCropEnded && rootRegion?.geometry?.type === 'FeatureCollection') {
    const {
      geometry: { features = [], type }
    } = rootRegion;

    const { filteredFeatures, children } = features.reduce(
      (acc, feature) => {
        if (!feature.properties?.current_info?.crop_ended) {
          acc.filteredFeatures.push(feature);
          acc.children.push(feature.properties.id);
        }
        return acc;
      },
      { filteredFeatures: [] as Feature<null, { current_info?: CurrentInfo; id: UUID }>[], children: [] as string[] }
    );

    rootRegion = {
      ...rootRegion,
      children,
      geometry: {
        features: filteredFeatures,
        type
      }
    };
  }

  return rootRegion;
};

export const selectRootRegion = createSelector(
  (state: AppState) => selectAll(state.entities.region),
  (state: AppState) => selectPropertyEntities(state.entities.property),
  (state: AppState) => selectSelectedProperty(state.uiState.global),
  (regions, properties, selectedProperty) =>
    selectedProperty && properties[selectedProperty] ? getRootRegion(regions, properties, selectedProperty) : null
);

export const selectRootRegionByCropStatus = createSelector(
  [
    (state: AppState) => selectAll(state.entities.region),
    (state: AppState) => getPropertyEntitiesInEntities(state),
    (state: AppState) => getSelectedPropertyId(state),
    (_, showFieldsCropEnded: boolean) => showFieldsCropEnded
  ],
  (regions, properties, selectedProperty, showFieldsCropEnded) => {
    return selectedProperty && properties[selectedProperty]
      ? getRootRegion(regions, properties, selectedProperty, showFieldsCropEnded)
      : null;
  }
);

export const selectRegionDictionaryByCurrentSeason = createSelector(
  (state: AppState) => selectAll(state.entities.region),
  (state: AppState) => state.uiState.global.selectedSeasons,
  (regions, seasons) => {
    return _.keyBy(
      regions.filter(r => {
        const seasonIds = r.seasons.map(s => s.id);
        return seasonIds.some(id => seasons.includes(id));
      }),
      'id'
    );
  }
);

export const selectRegionByCurrentSeason = createSelector(
  (state: AppState) => selectAll(state.entities.region),
  (state: AppState) => state.uiState.global.selectedSeasons,
  (regions, seasons) => {
    return regions.filter(r => {
      const seasonIds = r.seasons.map(s => s.id);
      return seasonIds.some(id => seasons.includes(id));
    });
  }
);

export const selectRegionByProperty = createSelector(
  (state: AppState) => getSelectedProperty(state),
  (state: AppState) => state.entities.region.entities,
  (property, regions) => {
    return property ? regions[property.root_region_id] : undefined;
  }
);

export const selectAreasByProperty = createSelector(
  (state: AppState) => getSelectedProperty(state),
  (state: AppState) => state.entities.region.entities,
  (property, regions) => {
    function getAreas(region_parent: Region, grouped_by_parent: Dictionary<Region[]>): Region[] {
      const children = grouped_by_parent[region_parent.id];

      return children
        ? children.reduce((acc: Region[], item) => {
            if (item.type === RegionType.AREA) {
              return [...acc, item];
            }
            return [...acc, ...getAreas(item, grouped_by_parent)];
          }, [])
        : [];
    }

    const region = property && regions[property.root_region_id];

    if (!region) return;

    const grouped_regions = _.groupBy(Object.values(regions), 'parent_id');

    return getAreas(region, grouped_regions);
  }
);

export const getRegions = (state: AppState): Dictionary<Region> => selectRegionEntities(state.entities.region);

const verifyPressureIndicator = (action: ReturnType<typeof LoadSeverityByIndicatorSuccess>): boolean | undefined => {
  return action.payload.data?.some(payloadData =>
    !isEmpty(payloadData.indexes?.[0].severity) ? payloadData.indexes[0].severity : undefined
  );
};

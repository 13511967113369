import sidebarAccordion from 'components/sidebar-accordion/redux/sidebar-accordion.reducer';
import { requestLoadCompanyData } from 'core/core.epics';
// Global
import global from 'core/core.reducer';
import { handleLoadHeatmaps, handleReloadHeatmaps } from 'core/services/analytics/heatmap/heatmap.epics';
// Heatmaps
import heatmap from 'core/services/analytics/heatmap/heatmap.reducer';
import {
  handleDeleteUploadFile,
  handleLoadUploadFileList,
  handleLoadUploadFileListImages,
  handlePostUploadFile,
  handleUpdateUploadFile
} from 'core/services/analytics/upload-file/upload-file.epics';
// UploadList
import uploadFile from 'core/services/analytics/upload-file/upload-file.reducer';
import { handleLoadWeather, handleLoadWeatherHourly } from 'core/services/analytics/weather/weather.epics';
// Weather
import weather from 'core/services/analytics/weather/weather.reducer';
import widgetsEpics from 'core/services/analytics/widgets/widgets.epics';
// Widgets
import widgets from 'core/services/analytics/widgets/widgets.reducer';
import {
  handleLoadOrganizationInfo,
  handleLoadWorkspaceApps,
  handleLoadWorkspaceInfo,
  handleLoadWorkspaceInfoAccess,
  handleLoadWorkspaceName,
  handleLoginRequest,
  handleLoginSuccess,
  handleLogoutRequest
} from 'core/services/auth/auth.epics';
// Auth
import auth from 'core/services/auth/auth.reducer';
import handleLoadAlerts from 'entities/alerts/alerts.epics';
// Alerts
import alerts from 'entities/alerts/alerts.reducer';
import { handleLoadAppsPermissions } from 'entities/apps/apps.epic';
import { handleLoadAssignees } from 'entities/assignee/assignee.epics';
// Assignee
import assignee from 'entities/assignee/assignee.reducer';
import borerRisk from 'entities/borer-risk/borer-risk.reducer';
import {
  handleLoadCompanies,
  handleLoadCurrentWorkspaceId,
  handleLoadOrgLicensingStatus,
  handleReloadCompanies
} from 'entities/company/company.epics';
// Company
import company from 'entities/company/company.reducer';
import { handleLoadCrop, handleLoadCropByIds, handleLoadCropVarieties } from 'entities/crop/crop.epics';
// Crop
import crop from 'entities/crop/crop.reducer';
import { handleLoadDaysIndicators, handleLoadWindowIndicator } from 'entities/indicators/indicators.epics';
import indicators from 'entities/indicators/indicators.reducer';
import {
  handleAuthenticateJDOC,
  handleGetAccessTrapView,
  handleGetAreasDiffJDOC,
  handleGetAreasDiffPerfectFlight,
  handleGetAuthenticateJDOC,
  handleGetAuthenticatePerfectFlight,
  handleGetAuthenticateTrapView,
  handleGetFarmsByOrganizationsJDOC,
  handleGetOrganizationsJDOC,
  handleGetPerfectFlightApplications,
  handleGetPermissionsJDOC,
  handleGetTrapViewBinding,
  handleGetTrapViewDevices,
  handleGetUpdateDiffJDOC,
  handleIntegratePerfectFlight,
  handleLoadPerfectFlightPropertyList,
  handlePostAreasDiffJDOC,
  handlePostInstallTrapView,
  handlePostIntegrateTrapView,
  handlePostIntegrateTrapViewExternal,
  handlePostPermissionsJDOC,
  handleRevokeAccessJDOC,
  handleRevokePerfectFlight,
  handleRevokeTrapView
} from 'entities/integrations/integrations.epics';
import integrations from 'entities/integrations/integrations.reducer';
import { handleLoadMethodologyDeep, handleUpdateMethodologyDeep } from 'entities/methodology-deep/methodology-deep.epics';
import { handleLoadMethodologyByCompany, handleUpdateMethodology } from 'entities/methodology/methodology.epics';
import {
  handleLoadMethodologies,
  handleLoadMethodology,
  handleLoadMethodologyArea,
  handleLoadPhenomenaByMethodology
} from 'entities/phenomenon/phenomenon.epics';
// Phenomenon
import phenomenon from 'entities/phenomenon/phenomenon.reducer';
import { handleLoadProducts, handleLoadProductsByIds } from 'entities/product/product.epics';
// Product
import product from 'entities/product/product.reducer';
import {
  handleGetCurrentSeasonAreas,
  handleGetPropertyCountry,
  handleGetSeasonAreasByDate,
  handleLoadIndicatorPressure,
  handleLoadProperties,
  handleLoadPropertiesAreaInfo,
  // handleLoadPropertiesSuccess,
  handleLoadPropertiesDiagnosticBreakdown,
  handleLoadProperty,
  handleLoadPropertyData,
  handleLoadPropertyPhenomena,
  handleLoadPropertyTime
} from 'entities/property/property.epics';
// Property
import monitoring from 'entities/monitoring/monitoring.reducer';
import property from 'entities/property/property.reducer';
import {
  handleDeleteAreaVariable,
  handleDeleteCustomVariable,
  handleDeletePhenology,
  handleEditAreaVariablesMassively,
  handleEditDatesMassively,
  handleEditVarietiesMassively,
  handleGetAreaVariable,
  handleGetAreaVariables,
  handleGetCustomVariable,
  handleGetRegionData,
  handleLoadAreaDayRepresentativeness,
  handleLoadFieldsCurrentInfoBatch,
  handleLoadRegion,
  handleLoadRegionDetailedInfo,
  handleLoadRegionSeasonAreas,
  handleLoadRegionSeasonFields,
  handleLoadWindowEvents,
  handlePostAreaVariable,
  handlePostCustomVariable,
  handleReloadRegion,
  handleReloadRegionDetailedInfo,
  handleSaveAreaVariablesMassively,
  handleSetTimelineFilter,
  handleUpdateAreaVariable,
  handleUpdateCustomVariable,
  handleUpdateDaysMapColors,
  handleUpdateRegionsNematodeDamage,
  handleUpdateRegionsProperties,
  handleUpdateSeedAndPhenologyColors
} from 'entities/region/region.epics';
import region from 'entities/region/region.reducer';
import { handleLoadReports, handleRequestReport } from 'entities/reports/report.epics';
// Reports
import reports from 'entities/reports/report.reducer';
import { handleSetSeasonsFromProperties, handleSetSeasonsFromProperty, selectActiveSeasonsAsActive } from 'entities/season/season.epics';
// Season
import season from 'entities/season/season.reducer';
import { handleCreateStaticPoint, handleLoadStaticPoint, handleLoadStaticPointTemplate } from 'entities/static-points/static-points.epics';
// Static Points
import staticPoints from 'entities/static-points/static-points.reducer';
import {
  handleCreateStationSuccess,
  handleDeleteStation,
  handleDeleteStationSuccess,
  handleEditStation,
  handleEditStationSuccess,
  handleLoadAnalyticResultsHistogram,
  handleLoadStationsPaginated,
  handleToggleStation
} from 'entities/stations/stations.epics';
// Stations
import stations from 'entities/stations/stations.reducer';
// Tasks
import task from 'entities/task/task.reducer';
import {
  handlGetEntryWarehouse,
  handlUpdateEntryWarehouse,
  handleCreateWarehouse,
  handleLoadAllTransactionsFromWarehousesId,
  handleLoadCompanyStockWarehouse,
  handleLoadIndividualStockWarehouse,
  handleLoadStockWarehouse,
  handleLoadTransactionsWarehouse,
  handleLoadWarehouse,
  handleNewEntryWarehouse,
  handleNewVendor,
  handleRemoveWarehouse,
  handleTransferWarehouse,
  handleUpdateVendor,
  handleUpdateWarehouse
} from 'entities/warehouse/warehouse.epics';
// Warehouse
import { handleAreaDayMonitoring } from 'entities/monitoring/monitoring.epics';
import { handleLoadConfigPowerBIReports, handleLoadPowerBIReports } from 'entities/powerbi-reports/powerbi-reports.epics';
import powerBIReports from 'entities/powerbi-reports/powerbi-reports.reducer';
import warehouse from 'entities/warehouse/warehouse.reducer';
import regionSideMenu from 'pages/static-points/regions-side-menu/redux/regions-side-menu.reducer';
import staticPointsDrawer from 'pages/static-points/static-points-drawer/redux/static-points-drawer.reducer';
import { combineReducers } from 'redux';
import type { Epic } from 'redux-observable';
import { combineEpics } from 'redux-observable';
import { handleLoadBorerRiskParameters, handleLoadBorerRisks } from '../entities/borer-risk/borer-risk.epics';
import methodologyDeep from '../entities/methodology-deep/methodology-deep.reducer';
import methodology from '../entities/methodology/methodology.reducer';
import type { AnalyticsState, AppState, ComponentsState, EntitiesState, UiState } from './app-state';

export const rootEpic = combineEpics<Epic>(
  handleLoadRegionSeasonAreas,
  handleLoadRegionSeasonFields,
  handleLoginRequest,
  handleLogoutRequest,
  handleLoginSuccess,
  handleGetAreaVariables,
  handleLoadOrganizationInfo,
  handleEditVarietiesMassively,
  handleEditAreaVariablesMassively,
  handleSaveAreaVariablesMassively,
  handleEditDatesMassively,
  handleLoadCompanies,
  handleReloadCompanies,
  handleLoadWorkspaceInfo,
  handleLoadWorkspaceInfoAccess,
  handleLoadWorkspaceName,
  handleLoadWorkspaceApps,
  requestLoadCompanyData,
  handleLoadRegion,
  handleLoadRegionDetailedInfo,
  handleUpdateRegionsProperties,
  handleUpdateDaysMapColors,
  handleUpdateRegionsNematodeDamage,
  handleUpdateSeedAndPhenologyColors,
  handleSetTimelineFilter,
  handleReloadRegion,
  handleLoadProperty,
  handleReloadRegionDetailedInfo,
  handleLoadWindowEvents,
  handleSetSeasonsFromProperty,
  handleSetSeasonsFromProperties,
  selectActiveSeasonsAsActive,
  handleLoadProperties,
  handleLoadPropertiesAreaInfo,
  handleLoadPropertyTime,
  handleLoadPropertiesDiagnosticBreakdown,
  handleGetPropertyCountry,
  widgetsEpics,
  handleLoadAssignees,
  handleLoadWarehouse,
  handleNewVendor,
  handleUpdateVendor,
  handleNewEntryWarehouse,
  handlUpdateEntryWarehouse,
  handlGetEntryWarehouse,
  handleLoadTransactionsWarehouse,
  handleLoadAllTransactionsFromWarehousesId,
  handleLoadIndividualStockWarehouse,
  handleLoadStockWarehouse,
  handleRemoveWarehouse,
  handleCreateWarehouse,
  handleUpdateWarehouse,
  handleLoadCompanyStockWarehouse,
  handleTransferWarehouse,
  handleLoadProducts,
  handleLoadProductsByIds,
  handleLoadCrop,
  handleLoadCropByIds,
  handleLoadMethodologies,
  handleLoadPhenomenaByMethodology,
  handleLoadCropVarieties,
  handleLoadUploadFileList,
  handleLoadUploadFileListImages,
  handlePostUploadFile,
  handleDeleteUploadFile,
  handleUpdateUploadFile,
  handleLoadHeatmaps,
  handleReloadHeatmaps,
  handleLoadAlerts,
  handleLoadWeather,
  handleLoadWeatherHourly,
  handleLoadDaysIndicators,
  handleLoadWindowIndicator,
  handleAuthenticateJDOC,
  handleGetAuthenticateJDOC,
  handleGetAuthenticatePerfectFlight,
  handleIntegratePerfectFlight,
  handleLoadPerfectFlightPropertyList,
  handleLoadBorerRiskParameters,
  handleLoadBorerRisks,
  handleGetPermissionsJDOC,
  handlePostPermissionsJDOC,
  handleRevokeAccessJDOC,
  handleGetOrganizationsJDOC,
  handleGetFarmsByOrganizationsJDOC,
  handleGetAreasDiffJDOC,
  handleGetAreasDiffPerfectFlight,
  handleRevokePerfectFlight,
  handlePostAreasDiffJDOC,
  handleGetUpdateDiffJDOC,
  handleGetCurrentSeasonAreas,
  handleGetSeasonAreasByDate,
  handleGetAreaVariable,
  handlePostAreaVariable,
  handleUpdateAreaVariable,
  handleDeleteAreaVariable,
  handleGetCustomVariable,
  handleDeleteCustomVariable,
  handleUpdateCustomVariable,
  handlePostCustomVariable,
  handleLoadMethodologyArea,
  handleLoadMethodology,
  handleLoadAppsPermissions,
  handleLoadOrgLicensingStatus,
  handleLoadCurrentWorkspaceId,
  handleLoadStaticPoint,
  handleLoadStaticPointTemplate,
  handleLoadStationsPaginated,
  handleLoadAnalyticResultsHistogram,
  handleLoadPropertyData,
  handleLoadFieldsCurrentInfoBatch,
  handleGetRegionData,
  handleCreateStaticPoint,
  handleCreateStationSuccess,
  handleDeleteStation,
  handleLoadReports,
  handleRequestReport,
  handleToggleStation,
  handleGetPerfectFlightApplications,
  handleEditStation,
  handleEditStationSuccess,
  handleLoadMethodologyByCompany,
  handleUpdateMethodology,
  handleLoadMethodologyDeep,
  handleUpdateMethodologyDeep,
  handleLoadAreaDayRepresentativeness,
  handleLoadPropertyPhenomena,
  handleLoadIndicatorPressure,
  handleGetAuthenticateTrapView,
  handleGetAccessTrapView,
  handlePostIntegrateTrapViewExternal,
  handlePostIntegrateTrapView,
  handleRevokeTrapView,
  handleGetTrapViewDevices,
  handleGetTrapViewBinding,
  handlePostInstallTrapView,
  handleLoadPropertyPhenomena,
  handleDeleteStationSuccess,
  handleLoadPowerBIReports,
  handleLoadConfigPowerBIReports,
  handleAreaDayMonitoring,
  handleDeletePhenology
);

export const rootReducer = () =>
  combineReducers<AppState>({
    analytics: combineReducers<AnalyticsState>({ heatmap, uploadFile, weather, widgets }),
    components: combineReducers<ComponentsState>({ regionSideMenu, sidebarAccordion, staticPointsDrawer }),
    entities: combineReducers<EntitiesState>({
      alerts,
      assignee,
      borerRisk,
      company,
      crop,
      indicators,
      integrations,
      methodology,
      methodologyDeep,
      monitoring,
      phenomenon,
      powerBIReports,
      product,
      property,
      region,
      reports,
      season,
      staticPoints,
      stations,
      task,
      warehouse
    }),
    uiState: combineReducers<UiState>({ auth, global })
  });

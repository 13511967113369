import _ from 'lodash';

export const deburrLowerCase = (searchStr: string): string => _.chain(searchStr).deburr().toLower().value();

export const slugify = (text: string | undefined | null): string => (text ? _.snakeCase(deburrLowerCase(text)) : '');

export const compareStrings = (str1: string, str2: string): number => str1.localeCompare(str2);

export const checkIfValueIsNumber = (value: string | number): boolean => typeof value === 'number';

/**
 * Using the logical OR operator (||) can cause issues with SonarCloud's TypeScript rule S6606,
 * as an empty string is considered a falsy value. This can lead to unintended behavior.
 *
 * For more details, refer to:
 * https://sonarcloud.io/organizations/syngenta-digital/rules?open=typescript%3AS6606&rule_key=typescript%3AS6606
 *
 * If the default value is an empty string, consider using the nullish coalescing operator (??) instead.
 */
export const getStringOrDefault = (value: string | undefined | null, defaultValue: string): string => {
  if (value) {
    return value;
  }

  return defaultValue;
};
